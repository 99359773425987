import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    return (
        <div className="roboto-regular container-md App pt-5">
            <div className="row">
                <div className="col-12 text-center">
                    <h2>Tomasz Maj IT services</h2>
                </div>
            </div>
            <div className="row d-flex justify-content-center pt-3">
                <div className="col-8">
                    <p>I am a passionate software developer with over 20 years of experience, dedicated to creating and
                        maintaining impactful solutions. My approach is deeply hands-on and proactive, always focused on
                        identifying opportunities for improvement.</p>

                    <p>My fascination with technology began with the development of ARPANET, inspiring me to explore
                        dynamic, widely accessible web pages. Over time, this passion evolved into a career in web
                        development, branching into areas like task automation scripting and early explorations in
                        ethical hacking.</p>

                    <p>My professional journey began with JavaScript, PHP, and SQL—considered robust tools of their
                        time. Recognizing the need for enterprise-grade solutions, I transitioned to Java, which
                        introduced me to core concepts like design patterns, test-driven development, and clean,
                        maintainable code structures. Collaborative environments taught me the value of teamwork in
                        delivering exceptional software.</p>

                    <p>As my career progressed, I sought roles that provided greater involvement in product development.
                        I led features from initial story definition through production release and ongoing monitoring,
                        gaining experience in team leadership and mentorship along the way. Recently, I’ve expanded my
                        technical repertoire to include Go, Ruby, and Python, with a consistent focus on full-stack
                        development through React.</p>

                    <p>IT security is one of my strongest passions. I find it rewarding to analyze and strengthen
                        software against vulnerabilities, understanding how development choices can expose systems to
                        risks like reverse-engineering or unauthorized modification. This mindset informs my approach to
                        designing secure, resilient solutions.</p>

                    <p>Another area of deep interest is artificial intelligence, which I believe is reshaping the world.
                        This curiosity has driven me to explore Rust, further diversifying my technical skill set.</p>

                    <p>Feel free to connect with me on <a
                        href="https://linkedin.com/in/tomasz-maj-e-maj">LinkedIn</a> or
                        explore my projects on <a href="https://github.com/tmaj87">GitHub</a>.</p>
                </div>
            </div>
            <div className="row pb-4">
                <div className="col-12 text-center pt-5">All rights reserved <b>Tomasz Maj</b> 2024</div>
            </div>
        </div>
    );
}

export default App;
